<template>
  <v-container tag="section">
    <validation-observer v-slot="{ valid }">
      <material-wizard
        title="Nova Importação"
        subtitle="Escolha o tipo de importação e faça o upload do arquivo a ser importado."
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
        :loading="loading"
        :disabledBtnFinalizar="disabledBtnFinalizar"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <first-tab-content @import:updated="handleTabContentUpdated" />
          </form>
        </v-tab-item>
        <v-tab-item class="pb-1">
          <second-tab-content
            @encoding:selected="handleEncodingSelected"
            @file:uploaded="handleFileUploaded"
            @apagarBaseAtual:changed="handleApagarBaseAtualChanged"
            @selectedCategories:changed="handleSelectedCategoriesChanged"
            :dataImportType="importacao.tipoImportacao"
            :formattedFieldsCategories="formattedFieldsCategories"
          />
        </v-tab-item>
      </material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
  import ImportacoesManutencoesTecnicosService from "@/services/ImportacoesManutencoesTecnicosService.js";
  import UsersService from "@/services/UsersService";
  import tourMixins from "@/mixins/tourMixins";

  export default {
    name: "ManutencaoTecnicosImportacoesAdd",
    mixins: [tourMixins],
    components: {
      MaterialWizard: () => import("@/components/base/MaterialWizard"),
      FirstTabContent: () =>
        import(
          "@/components/manutencao-tecnicos/importacoes/tabs/FirstTabContent.vue"
        ),
      SecondTabContent: () =>
        import(
          "@/components/manutencao-tecnicos/importacoes/tabs/SecondTabContent.vue"
        ),
    },
    data: () => ({
      tab: 0,
      tabs: ["Informações básicas", "Upload de arquivo"],
      indicadoresFieldsCategories: {},
      userIndicadoresFieldsCategories: null,
      selectedCategories: [],
      loading: false,
      importacao: {
        titulo: null,
        descricao: null,
        companyId: null,
        indgerVersion: null,
        data: null,
        mes: null,
        tipoImportacao: null,
        arquivo: null,
        encoding: "UTF-8",
        apagarBaseAtual: false,
      },
      arrTourPrimeiraTab: [
        {
          element: "#fs-titulo-importacao-dados",
          intro: "Aqui você deverá inserir um título para sua importação.",
          scrollTo: "tooltip",
          position: "bottom",
        },
        {
          element: "#fs-descricao-importacao-dados",
          intro: "Aqui você poderá inserir uma descrição para sua importação.",
          scrollTo: "tooltip",
          position: "bottom",
        },
        {
          element: "#fs-empresa-importacao-dados",
          intro:
            "Aqui você deverá selecionar a empresa alvo que receberá a importação.",
          scrollTo: "tooltip",
          position: "bottom",
        },
        {
          element: "#fs-tipo-importacao-dados",
          intro:
            "Aqui você deverá informar o tipo de importação que será feita (AIS, Laudo, etc).",
          scrollTo: "tooltip",
          position: "bottom",
        },
      ],
      arrTourSegundaTab: [
        {
          element: "#fs-upload-importacao-dados",
          intro:
            "Nesta seção deverá ser enviado o arquivo com os dados a serem importados.",
          scrollTo: "tooltip",
          position: "top",
        },
      ],
    }),
    created() {
      this.iniciarTourPrimeiraTab();
      this.getIndicadoresFieldsCategories();
      this.getIndicadoresFieldsCategoriesByUser();
    },
    computed: {
      availableSteps() {
        let steps = [0];

        if (this.tab1IsValid) {
          steps.push(1);
        }

        if (this.tab2IsValid) {
          steps.push(2);
        }

        return steps;
      },
      tab1IsValid() {
        const { titulo, tipoImportacao, mes } = this.importacao;

        return (
          titulo !== null &&
          titulo.trim() !== "" &&
          tipoImportacao !== null &&
          mes !== null
        );
      },
      tab2IsValid() {
        return (
          this.tab1IsValid &&
          this.selectedCategories.length > 0 &&
          this.importacao.arquivo !== null
        );
      },
      disabledBtnFinalizar() {
        if (this.tab === 0) {
          return !this.tab1IsValid;
        }

        if (this.tab === 1) {
          return !this.tab2IsValid;
        }
      },
      formattedFieldsCategories() {
        if (!this.importacao?.tipoImportacao) return [];

        const fieldsCategories = {
          "TEC_AL": this.$store.getters.getUserInfo.admin 
            ? this.indicadoresFieldsCategories?.fields_indicadores_alimentadores_categories
            : this.userIndicadoresFieldsCategories?.fields_indicadores_alimentadores_categories, 
          "TEC_SUB": this.$store.getters.getUserInfo.admin 
            ?this.indicadoresFieldsCategories?.fields_indicadores_subestacoes_categories
            : this.userIndicadoresFieldsCategories?.fields_indicadores_subestacoes_categories, 
          "TEC_LD": this.$store.getters.getUserInfo.admin 
            ? this.indicadoresFieldsCategories?.fields_indicadores_linhas_distribuicao_categories
            : this.userIndicadoresFieldsCategories?.fields_indicadores_linhas_distribuicao_categories, 
        };

        return fieldsCategories[this.importacao?.tipoImportacao] ?? [];
      }
    },
    methods: {
      getIndicadoresFieldsCategories() {
        return UsersService.getIndicadoresFieldsCategories(true)
          .then((res) => {
            this.indicadoresFieldsCategories = res;
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar temas dos indicadores.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      getIndicadoresFieldsCategoriesByUser() {
        // Dados necessários apenas para perfil Validador
        if (!this.$store.getters.getUserInfo.validador) {
          return;
        }

        return UsersService.getIndicadoresFieldsCategoriesByUser(this.$store.getters.getUserId, true)
          .then(res => {
            this.userIndicadoresFieldsCategories = res;
          })
          .catch(err => {
            this.$toast.error('Erro ao recuperar temas dos indicadores.', '', {
              position: 'topRight'
            });
            throw err;
          });
      },
      async next(valid) {
        if (!valid) return;

        if (this.tab === 0 && !this.tab1IsValid) {
          return;
        }

        if (this.tab === 1) {
          if (!this.tab2IsValid) {
            return;
          }

          const sucesso = await this.cadastraNovaImportacao();
          if (!sucesso) {
            return;
          }

          this.$router.push({
            name: "Manutenção Técnicos Importacões Index",
          });
        } else {
          this.tab++;
        }
      },
      handleTabContentUpdated(event) {
        this.importacao = Object.assign(this.importacao, event);
      },
      handleFileUploaded(file) {
        this.importacao.arquivo = file;
      },
      handleEncodingSelected(event) {
        this.importacao.encoding = event;
      },
      handleApagarBaseAtualChanged(event) {
        this.importacao.apagarBaseAtual = event;
      },
      handleSelectedCategoriesChanged(event) {
        this.selectedCategories = event;
      },
      async cadastraNovaImportacao() {
        let sucesso = false;
        this.loading = true;

        try {
          const {
            titulo,
            descricao,
            data,
            arquivo,
            encoding,
            tipoImportacao,
            apagarBaseAtual,
          } = this.importacao;

          const importacao = {
            titulo,
            descricao,
            competencia: data,
            arquivo_id: arquivo.id,
            encoding,
            tipo_importacao: tipoImportacao,
            apagar_base_atual: apagarBaseAtual,
            temas_selecionados: this.selectedCategories,
          };
          sucesso = await ImportacoesManutencoesTecnicosService.save(importacao);
          this.$toast.success("Importação cadastrada com sucesso.", "", {
            position: "topRight",
          });
        } catch (e) {
          this.$toast.error("Ocorreu um erro ao cadastrar a Importação.", "", {
            position: "topRight",
          });
          console.error(e);
        } finally {
          this.loading = false;
        }

        return sucesso;
      },
      iniciarTourPrimeiraTab() {
        this.doneLabel = "Próximo";
        this.desativarTourAoSair = false;

        let onBeforeExitFunction = {
          func: () => {
            this.tab = 1;
            this.initTourSegundaTab();
          },
        };

        this.iniciarTour(
          this.arrTourPrimeiraTab,
          [],
          [],
          [onBeforeExitFunction]
        );
      },
      initTourSegundaTab() {
        this.doneLabel = "Finalizar";
        this.desativarTourAoSair = true;

        let onBeforeExitFunction = {
          func: () => (this.tab = 0),
        };

        this.iniciarTour(
          this.arrTourSegundaTab,
          [],
          [],
          [onBeforeExitFunction]
        );
      },
    },
  };
</script>
